import { tw } from '@/utils/tw';
import { default as BaseTagTheme } from 'base/components/Tag/theme';

const Tag = tw.theme({
  extend: BaseTagTheme,
  base: 'focus:ring-0',
  variants: {
    colors: {
      default: `bg-gray-50 text-black hover:bg-logga-400 focus:bg-logga-400 active:bg-logga-400 active:shadow-[inset_0_0_4px_rgba(82,0,45,0.3)]`,
      text: `text-black`,
      alternative: 'text-logga-700',
    },
    size: {
      small: `px-3 py-1.5 text-button-sm`,
      medium: `px-3 py-2.25 text-button-sm`,
      large: `px-3.5 py-2 text-button-md`,
      text: `text-button-sm`,
      video: 'capitalize text-category-md',
    },
  },
});

export default Tag;
