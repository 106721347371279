/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "front_page": {
    "content_variant_vertical": "flex flex-col gap-3",
    "content_size_vertical": "",
    "media_video_size_default": "w-full",
    "media_image_size_default": "w-full",
    "content_title_size_vertical": "text-headline-xs container-col-sm-7:text-headline-xs sm:text-headline-md sm:container-col-lg-4:text-headline-sm sm:container-col-lg-7:text-headline-sm",
    "variant_vertical": "container-inline",
    "size_vertical": "w-full not-prose",
    "content_hint_variant_vertical": "!font-normal",
    "content_hint_size_vertical": "text-button-sm md:text-button-md",
    "content_hint_colors_primary": "text-logga-700"
  },
  "video_pages": {
    "content_variant_vertical": "flex flex-col",
    "content_size_vertical": "gap-2",
    "content_size_horizontal": "gap-3",
    "media_video_size_horizontal": "w-full max-w-[310px] max-h-[180px] aspect-video rounded-md",
    "media_image_size_vertical": "w-full max-w-[160px] max-h-[285px] md:max-w-[310px] md:max-h-[540px] aspect-vertical",
    "media_image_variant_vertical": "object-cover",
    "media_playIcon_variant_vertical": "absolute left-1/2 top-auto transform bottom-3 transform -translate-x-1/2 hidden",
    "content_title_size_vertical": "text-headline-xs md:text-headline-sm line-clamp-3",
    "variant_vertical": "container-inline",
    "size_vertical": "w-full not-prose",
    "content_title_size_horizontal": "text-headline-xs md:text-headline-sm line-clamp-3",
    "media_size_horizontal": "basis-[140px] md:basis-[300px] h-fit",
    "extend": {
      "media_playIcon_variant_vertical": "hidden",
      "media_playIcon_variant_horizontal": "hidden",
      "media_video_size_vertical": "aspect-video"
    }
  },
  "tag": {
    "variant_default": "",
    "colors_text": "text-logga-700",
    "size_text": "text-category-md uppercase"
  },
  "headline_variant_default": "flex items-center uppercase",
  "headline_variant_horizontal": "",
  "headline_variant_vertical": "",
  "headline_size_default": "text-headline-xs mb-1.5",
  "headline_size_horizontal": "",
  "headline_size_vertical": "",
  "headline_colors_default": "",
  "headline_colors_primary": "",
  "headline_colors_secondary": "",
  "headline_line_variant_default": "",
  "headline_line_variant_vertical": "",
  "headline_line_variant_horizontal": "",
  "headline_line_colors_default": "after:bg-black",
  "headline_line_colors_primary": "",
  "headline_line_colors_secondary": "",
  "headline_line_size_default": "",
  "headline_line_size_vertical": "after:ml-1.5 after:flex-1 after:h-px",
  "headline_line_size_horizontal": "after:ml-1.5 after:flex-1 after:h-px",
  "content_size_default": "",
  "content_size_vertical": "p-3",
  "content_size_horizontal": "p-3",
  "content_colors_default": "",
  "content_colors_primary": "bg-yellow-600",
  "content_colors_secondary": "border-primary-900 bg-gray-100",
  "content_variant_default": "",
  "content_variant_horizontal": "flex gap-3",
  "content_variant_vertical": "flex flex-col md:flex-row gap-3",
  "content_title_variant_default": "",
  "content_title_variant_horizontal": "",
  "content_title_variant_vertical": "",
  "content_title_size_default": "",
  "content_title_size_horizontal": "text-headline-xs md:text-headline-md md:text-headline-sm mb-2 md:mb-3",
  "content_title_size_vertical": "text-headline-sm",
  "content_title_colors_default": "",
  "content_title_colors_secondary": "text-white",
  "content_title_colors_primary": "text-black",
  "content_text_variant_default": "flex flex-col items-start",
  "content_text_variant_horizontal": "",
  "content_text_variant_vertical": "flex flex-col items-start",
  "content_text_size_default": "gap-3",
  "content_text_size_horizontal": "",
  "content_text_size_vertical": "gap-1.5",
  "content_text_colors_default": "",
  "content_text_colors_primary": "",
  "content_text_colors_secondary": "",
  "content_hint_variant_default": "font-bold",
  "content_hint_variant_horizontal": "",
  "content_hint_variant_vertical": "",
  "content_hint_colors_default": "",
  "content_hint_colors_primary": "text-logga-700",
  "content_hint_colors_secondary": "text-primary-800",
  "content_hint_size_vertical": "",
  "content_hint_size_horizontal": "text-button-sm md:text-button-md",
  "content_hint_size_default": "text-headline-xs md:text-headline-sm",
  "media_variant_default": "",
  "media_variant_vertical": "grow-0 shrink-0 relative",
  "media_variant_horizontal": "relative flex overflow-hidden grow-0 shrink-0",
  "media_size_default": "",
  "media_size_vertical": "rounded-lg h-fit",
  "media_size_horizontal": "rounded-none basis-[156px] md:basis-[200px] h-fit",
  "media_colors_default": "",
  "media_colors_primary": "",
  "media_colors_secondary": "",
  "media_video_variant_default": "",
  "media_video_variant_horizontal": "",
  "media_video_variant_vertical": "",
  "media_video_size_default": "w-full",
  "media_video_size_horizontal": "w-full",
  "media_video_size_vertical": "w-full",
  "media_video_colors_default": "",
  "media_video_colors_primary": "",
  "media_video_colors_secondary": "",
  "media_image_variant_default": "",
  "media_image_variant_horizontal": "",
  "media_image_variant_vertical": "",
  "media_image_size_default": "w-full",
  "media_image_size_horizontal": "w-full",
  "media_image_size_vertical": "w-full",
  "media_image_colors_default": "",
  "media_image_colors_primary": "",
  "media_image_colors_secondary": "",
  "media_playIcon_size_default": "w-9",
  "media_playIcon_size_vertical": "w-9",
  "media_playIcon_size_horizontal": "w-9 left-1.5 bottom-1.5",
  "media_playIcon_variant_default": "absolute",
  "media_playIcon_variant_horizontal": "absolute left-2 bottom-2",
  "media_playIcon_variant_vertical": "absolute left-3 top-3",
  "media_playIcon_colors_default": "",
  "media_playIcon_colors_primary": "",
  "media_playIcon_colors_secondary": "",
  "media_icon_colors_default": "text-logga-500 [&>path:last-of-type]:fill-white",
  "media_icon_colors_primary": "text-primary-300 [&>symbol>path]:fill-primary-900",
  "media_icon_colors_secondary": "text-primary-300 [&>symbol>path]:fill-white",
  "media_icon_size_default": "",
  "media_icon_size_vertical": "",
  "media_icon_size_horizontal": "",
  "media_icon_variant_default": "",
  "media_icon_variant_horizontal": "",
  "media_icon_variant_vertical": "",
  "variant_horizontal": "container-inline",
  "colors_default": "",
  "colors_primary": "",
  "colors_secondary": "",
  "size_horizontal": "w-full not-prose"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "JwVideoTeaser");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;